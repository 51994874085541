import QuantityInput from "./quantityInput";
import CheckboxInput from "./checkboxInput";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import AlertModal from "./form.alertModal";
import FormStatus from "./form.status";
import BusinessSocialNetworks from "./form.socialNetworks";
import i18n from '../../../i18n.js'
import {businessUpdate} from "../../api/businessEndpoints";
import PercentageInput from "./percentageInput";
import {Link} from "react-router-dom";

export default function BusinessForm(props) {

    const {setQuotationSuccess, businessId, businessCategories, socialNetworks, language} = (props) = props;
    const [show, setShow] = useState(false);
    const [repeated, setRepeated] = useState(""); //unique property in DDBB
    const [sendState, setSendState] = useState(null);
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [networkValues, setNetworkValues] = useState([])

    const businessDescriptionRequired = i18n.t('form-error-messages.business-description');
    const businessDescriptionMax = i18n.t('form-error-messages.business-description-max');
    const businessDescriptionMin = i18n.t('form-error-messages.business-description-min');
    const businessDomainRequired = i18n.t('form-error-messages.business-domain');
    const businessDomainPattern = i18n.t('form-error-messages.business-domain-pattern');
    const businessCategoryRequired = i18n.t('form-error-messages.business-category');
    const quantityRequired = i18n.t('form-error-messages.business-quantity');
    const quantityMin = i18n.t('form-error-messages.business-quantity-min');
    const percentageMax = i18n.t('form-error-messages.business-percentage-max');

    /**
     * Recives data from form after validation
     * @param data object
     */

     const onSubmit = async (data) => {



        setRepeated(data.businessDomain);
        setSendState(1);

        const formDataRes = new FormData();

        formDataRes.append('id', businessId);
        formDataRes.append('network_values', JSON.stringify(networkValues));
        formDataRes.append('category_id', data.businessCategory);
        formDataRes.append('description', data.businessDescription);
        formDataRes.append('domain_url', data.businessDomain);
        formDataRes.append('business_age_stat', data.businessAge);
        formDataRes.append('average_gross_revenue_stat', data.businessGross);
        formDataRes.append('average_gross_profit_stat', data.businessProfit);
        formDataRes.append('total_monthly_visits_stat', data.businessVisits);
        formDataRes.append('total_monthly_organic_visits_stat', data.businessOrganicVisits);
        formDataRes.append('total_social_network_followers_stat', data.businessFollowers);
        formDataRes.append('total_email_subscribers_stat', data.businessSubscribers);
        formDataRes.append('is_domain_stat', data.chkDomain?1:0);
        formDataRes.append('is_app_stat', data.chkApps?1:0);
        formDataRes.append('is_website_stat', data.chkWebsite?1:0);
        formDataRes.append('is_registered_brand_stat', data.chkBrand?1:0);

        try{
            businessUpdate(formDataRes).then(function (response) {
                 if(response.status==200){
                     //stored ok
                    setSendState(2);
                    setQuotationSuccess(true);
                 }
            }).catch(function (response) {
                //setSendState(0)
              if(response.request.status==422){
                  setSendState(422)
                  setShow(true);
                  }
                if(response.request.status==500){
                    setSendState(0)
                    console.log(response)
                }
            });

        }catch (e) {
           setSendState(0)
        }


    }

    const forCategories = !businessCategories? [] : businessCategories.map((bc, index) =>
        (
            <option value={bc.id} key={index}>{language==="es"?bc.name_es:bc.name_pt}</option>
        )
    );

    return(
        <>

            <form onSubmit={handleSubmit(onSubmit)} noValidate>

                <h4 className={"pt-4 pb-2"}>{i18n.t('quotation.form-up-business.title1')}</h4>
                {/*<p className={"alert alert-warning text-center"} role={"alert"}>{i18n.t('quotation.form-business.alert')}</p>]*/}

                <fieldset className="col-12 col-md-6">
                    <label className="label-element">{i18n.t('quotation.form-business.label')}</label>
                    <select className={errors.businessCategory?"input-info error":"input-info success"}
                            {...register("businessCategory", { required: true })} >
                        <option value="">{i18n.t('quotation.form-business.text1')}</option>
                        {forCategories}

                    </select>
                    <div className={"error-message text-danger"}>
                        {errors.businessCategory?.type === 'required' && businessCategoryRequired}

                    </div>
                </fieldset>

                <fieldset className="col-12">
                    <label className="label-element">{i18n.t('quotation.form-business.label1')}</label>
                    <textarea
                        className={errors.businessDescription?"error":"success"}
                        maxLength={490}
                        placeholder={i18n.t('quotation.form.placeholders.description')}
                        {...register("businessDescription", { required: true, maxLength: 490, minLength: 10  })} />
                    <div className={"error-message text-danger"}>
                        {errors.businessDescription?.type === 'required' && businessDescriptionRequired}
                        {errors.businessDescription?.type === 'maxLength' && businessDescriptionMax}
                        {errors.businessDescription?.type === 'minLength' && businessDescriptionMin}
                    </div>
                </fieldset>

                <div className={"row"}>
                    <fieldset className="col-12 col-md-6">
                        <label className="label-element">{i18n.t('quotation.form-business.label2')}</label>
                        <input className={errors.businessDomain?"input-info error":"input-info success"}
                               type="text"
                               placeholder={i18n.t('quotation.form.placeholders.domain')}
                               {...register("businessDomain", { required: true })} />
                        <div className={"error-message text-danger"}>
                            {errors.businessDomain?.type === 'required' && businessDomainRequired}

                        </div>

                    </fieldset>


                    <QuantityInput
                        label={i18n.t('quotation.form-business.label3')}
                        placeholder={i18n.t('quotation.form.placeholders.age')}
                        inputName="businessAge"
                        err={errors.businessAge}
                        quantityRequired={quantityRequired}
                        quantityMin={quantityMin}
                        register={register}/>

                    <QuantityInput
                        label={i18n.t('quotation.form-business.label4')}
                        placeholder={i18n.t('quotation.form.placeholders.revenue')}
                        inputName="businessGross"
                        err={errors.businessGross}
                        quantityRequired={quantityRequired}
                        quantityMin={quantityMin}
                        register={register}/>

                    <QuantityInput
                        label={i18n.t('quotation.form-business.label5')}
                        placeholder={i18n.t('quotation.form.placeholders.profit')}
                        inputName="businessProfit"
                        err={errors.businessProfit}
                        quantityRequired={quantityRequired}
                        quantityMin={quantityMin}
                        register={register}/>

                    <QuantityInput
                        label={i18n.t('quotation.form-business.label6')}
                        placeholder={i18n.t('quotation.form.placeholders.quantity')}
                        inputName="businessVisits"
                        err={errors.businessVisits}
                        quantityRequired={quantityRequired}
                        quantityMin={quantityMin}
                        register={register}/>

                    <PercentageInput
                        label={i18n.t('quotation.form-business.label7')}
                        placeholder={i18n.t('quotation.form.placeholders.quantity')}
                        inputName="businessOrganicVisits"
                        err={errors.businessOrganicVisits}
                        quantityRequired={quantityRequired}
                        quantityMin={quantityMin}
                        percentageMax={percentageMax}
                        register={register}/>

                    <QuantityInput
                        label={i18n.t('quotation.form-business.label8')}
                        placeholder={i18n.t('quotation.form.placeholders.quantity')}
                        inputName="businessFollowers"
                        err={errors.businessFollowers}
                        quantityRequired={quantityRequired}
                        quantityMin={quantityMin}
                        register={register}/>

                    <QuantityInput
                        label={i18n.t('quotation.form-business.label9')}
                        placeholder={i18n.t('quotation.form.placeholders.quantity')}
                        inputName="businessSubscribers"
                        err={errors.businessSubscribers}
                        quantityRequired={quantityRequired}
                        quantityMin={quantityMin}
                        register={register}/>


                </div>

                <h4 className={"pt-4 pb-2"}>{i18n.t('quotation.form-business.title2')}</h4>

                {/*
                <p>{i18n.t('quotation.form-business.text2')}</p>

                <BusinessSocialNetworks
                    socialNetworks={socialNetworks}
                    networkValues={networkValues}
                    setNetworkValues={setNetworkValues}
                    errors={errors}
                />
                */}


                <p>{i18n.t('quotation.form-business.text2')} <b style={{ fontSize: 'small', display: 'block'}}>{i18n.t('quotation.form-business.text2_aclaration')}</b></p>


                <div className={"row"}>

                    <fieldset className="col-6 col-md-3">
                        <CheckboxInput
                            label={"Dominio"}
                            inputName="chkDomain"
                            err={errors.businessDomain}
                            required={false}
                            message={""}
                            register={register}/>
                    </fieldset>

                    <fieldset className="col-6 col-md-3">
                        <CheckboxInput
                            label={"Marca registrada"}
                            inputName="chkBrand"
                            err={errors.chkBrand}
                            required={false}
                            message={""}
                            register={register}/>
                    </fieldset>

                    <fieldset className="col-6 col-md-3 mt-2 mt-md-0">
                        <CheckboxInput
                            label={"Apps"}
                            inputName="chkApps"
                            err={errors.chkApps}
                            required={false}
                            message={""}
                            register={register}/>
                    </fieldset>

                    <fieldset className="col-6 col-md-3 mt-2 mt-md-0">
                        <CheckboxInput
                            label={"Web"}
                            inputName="chkWebsite"
                            err={errors.chkWebsite}
                            required={false}
                            message={""}
                            register={register}/>
                    </fieldset>
                </div>

                <hr/>


                <FormStatus sendState={sendState} type={"domain"}/>


                <div className="col-12 submit-botton">
                    <p className={"text-left"}>
                    {i18n.t('quotation.form-business.text3')} <br/>
                    {i18n.t('quotation.form-business.text4')}<br/>
                    {i18n.t('quotation.form-business.text5')} <a href="" data-bs-toggle="modal" data-bs-target="#termsModal">{i18n.t('quotation.form-business.text6')}</a> </p>
                    <input className="btn btn-outline-primary g-action-button rounded-pill"
                           disabled={sendState === 1 }
                           type="submit"
                           value={i18n.t('quotation.form-business.send-button')}
                           id={"enviar-formulario"}/>
                </div>
            </form>

            <AlertModal show={show}  setShow={setShow} repeated={repeated} type={"domain"}/>

        </>
    )
}